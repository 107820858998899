import Vue from 'vue'
import App from './App.vue'
// Vue.prototype.$loading = Loading.service
// Vue.prototype.$message = Message
// Vue.prototype.$confirm = MessageBox.confirm
// Dialog.props.closeOnClickModal.default = false
// 引入封装的router
import router from '@/router/index'
Vue.config.productionTip = false
// 路由守卫
import Bus from '@/utils/bus'
Vue.use(Bus)
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import './css/style.css' /*引入公共样式*/
import 'lib-flexible/flexible.js'


export default new Vue({
  render: h => h(App),
  router
}).$mount('#app')
router.beforeResolve((to, from, next)=>{
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        //
    }
    next();
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
})
